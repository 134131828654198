import React, {Suspense, lazy} from "react";
import {Link} from "react-router-dom";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import Loader from "../Loader";

const Services  =  lazy(() => import("../Home/Services"));

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: {email: "", abo: true, otherAbo: true}
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {token}
      }
    } = this.props;

    const url = `/api/v1/subscriptions/unsub/${token}`;
    const csrToken = document.querySelector('meta[name="csrf-token"]').content;

    toastr.options = {
      positionClass: "toast-top-full-width",
      hideDuration: 300,
      timeOut: 3000
    };

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrToken,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.ok) {
          toastr.clear();
          setTimeout(
            () =>
              toastr.success(
                `Nous vous confirmons la supression de votre abonnement à la Newsletter JPXMotors.`
              ),
            300
          );
          return response.json();
        } else {
          setTimeout(() => toastr.error("Oups!"), 300);
          throw new Error("Network response was not ok.");
        }
      })
      .then(response => this.setState({subscription: response}))
      .catch(() => this.props.history.push("/decouvrir-nos-projets"));
  }

  render() {
    const {subscription} = this.state;

    return (
      <React.Fragment>
        <section className="hero is-medium is-primary banner-news">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-1 is-white" title="Newsletter JPXMotors!">
                Newsletter
              </h1>
            </div>
          </div>
        </section>

        <section className="hero is-medium is-ligth">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-2">Confirmation de suppression</h1>
              <h2 className="subtitle is-4 pt-4">{subscription.email}</h2>
              <div className="content is-medium">
                <p>Nous espérons vous revoir parmis nous très vite.</p>
                <p>
                  Nous vous confirmons votre supression de la liste de diffusion
                  de notre Newsletter.
                </p>
              </div>

              <div className="has-text-centered pt-3">
                <Link to="/" className="button is-primary is-medium">
                  Retour à la page d'accueil
                </Link>
              </div>
            </div>
          </div>
        </section>

        <Suspense fallback={<Loader sectionName="small" />}>
          <Services />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Confirmation;
