import React from "react";
const jpxImage = require("../../../assets/images/pictures/IMG_4014.JPG");
export default () => (
  <React.Fragment>
    <section className="section">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-two-fifths has-text-left is-hidden-touch">
            <figure className="image is-3by5">
              <img
                alt="JPXMotors"
                src={jpxImage}
                width="100%"
                height="100%"
              />
            </figure>
          </div>

          <div className="column is-full-mobile is-full-tablet is-three-fifths">
            <div className="content is-medium">
              <h2
                className="title is-3 my-6 is-primary title-logo has-text-centered"
                title="Mon histoire"
              >
                Mon histoire
              </h2>
              <p className="has-text-justified">
                Société spécialisée dans l’entretien, le diagnostique, la
                réparation & la restauration de <strong>MASERATI</strong>, de
                toutes générations (60’ à nos jours).
              </p>
              <p className="has-text-justified">
                Elle est basée dans le Vexin dans l’atypique ville de Marines à
                35 minutes de L’ouest Parisien.
              </p>
              <p className="has-text-justified">
                Elle s’appuie sur mon expertise, ayant passé plus d’une
                vingtaine d’années dans l’industrie Aéronautique. J'ai été formé
                sur des technologies de pointes et des mécaniques complexes
                (Rafale et Mirage 2000...).
              </p>
              <p className="has-text-justified">
                J'ai depuis longtemps transposé ces techniques au service de ma
                passion automobile.
              </p>
              <p className="has-text-justified">
                Tombé sous le charme des <strong>Mécaniques Italiennes</strong>,
                J'ai depuis 15 ans jeté mon dévolue sur la firme au Trident.
              </p>
              <p className="has-text-justified">
                La Genèse des <strong>Biturbo</strong> n’ayant plus de secret,
                l’histoire de cette firme m'a poussée à découvrir l’intégralité
                de la gamme de la création à nos jours.
              </p>
              <p className="has-text-justified">
                Je propose désormais mes services dans une singulière et
                discrète officine.
              </p>
              <h3 className="subtitle is-4 my-4 is-primary">
                Les Anciennes et modernes se croisent avec une seule volonté
                pour <strong className="is-logo is-primary">JPXMotors</strong>,
                vous garantir un plaisir d’utilisation de tous les instants...
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);