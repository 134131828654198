import React from "react";

export default () => (
  <section className="section">
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-full-mobile is-two-fifths has-text-left pt-6">
          <figure className="image is-5by4">
            <img alt="JPXMotors" src="/64-maserati-galery-pic01.jpg" />
          </figure>
        </div>

        <div className="column is-full-mobile is-three-fifths">
          <div className="content is-medium">
            <h2
              className="title is-3 mt-6 mb-3 is-primary"
              title="Modélisation & fabrication de pièces indisponible "
            >
              Modélisation & fabrication de pièces indisponible
            </h2>
            <p className="has-text-justified">
              L’indisponibilité de bon nombres de pièces rendent la maintenance
              de nos autos compliquée voire impossible.
            </p>

            <p className="has-text-justified">
              <strong>JPX</strong> Motors fort de son expérience industrielle
              utilise des technologies de pointes (Scanner 3D, CAO...).
            </p>

            <p className="has-text-justified">
              Et adaptons la technologie au besoin, afin de maitriser les couts
              liés à la fabrication unitaire de pièces complexes.
            </p>

            <h3 className="subtitle is-4 my-4 is-primary">
              Des partenariats avec des sociétés hexagonales nous permettent une
              maitrise totales du process de fabrication et des délais associés.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
);