import React, {Suspense, lazy} from "react";

import Loader from "../Loader";

const Services = lazy(() => import("./Services"));
const Team = lazy(() => import("../About/Team"));
const Story = lazy(() => import("../About/Story"));
const Modelisation = lazy(() => import("../OurServices/Modelisation"));
const Contact = lazy(() => import("../Contact/Banner"));
const Header = lazy(() => import("../OurServices/Header"));
const Solution = lazy(() => import("../Home/Solution"));

export default () => (
  <React.Fragment>
    <section className="hero is-medium is-primary banner">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1
            className="title is-1 is-size-2-mobile is-white my-6 is-logo"
            title="Nos projets"
          >
            Nos projets
          </h1>
        </div>
      </div>
    </section>

    <Suspense fallback={<Loader sectionName="small" />}>
      <Header />
      <Services />
      <Modelisation />
      <Team />
      <Story />
      <Contact />
      <Solution />
    </Suspense>
  </React.Fragment>
);