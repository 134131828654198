import React, {Suspense, lazy} from "react";

import Loader from "../Loader";

const Reparation = lazy(() => import("./Reparation"));
const Diagnostiques = lazy(() => import("./Diagnostiques"));
const ContactBanner = lazy(() => import("../Contact/Banner"));

export default function OurServices() {
  return (
    <React.Fragment>
      <section className="hero is-medium is-primary banner">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1
              className="title is-1 is-size-2-mobile is-white my-6 is-logo"
              title="Nos services"
            >
              Nos services
            </h1>
          </div>
        </div>
      </section>

      <Suspense fallback={<Loader sectionName="small" />}>
        <Reparation />
        <Diagnostiques />
        <ContactBanner />
      </Suspense>
    </React.Fragment>
  );
}
