import React, {Suspense} from "react";
import {Router} from "react-router-dom";
import {Provider} from "mobx-react";
import {syncHistoryWithStore} from "mobx-react-router";
import {createBrowserHistory} from "history";

import ReactGA from "react-ga";
ReactGA.initialize("UA-187758771");

import Navigation from "./Navigation";
import store from "../store";

import Loader from "../components/Loader";
import Header from "../components/Header";
import Footer from "../components/Footer";

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, store.routing);
history.listen(location => {
  document.documentElement.scrollTop = 0;
  ReactGA.pageview(window.location.pathname + window.location.search);
});

export default (
  <Provider {...store}>
    <Router history={history}>
      <Header />
      <Suspense fallback={<Loader />}>
        <Navigation />
      </Suspense>
      <Footer />
    </Router>
  </Provider>
);
