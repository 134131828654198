import React, {Suspense, lazy} from "react";
import {Link} from "react-router-dom";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import Loader from "../Loader";

const Services  = lazy(() => import("../Home/Services"));

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: {email: "", abo: true, otherAbo: true}
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {token}
      }
    } = this.props;

    const url = `/api/v1/subscriptions/confirmation/${token}`;
    const csrToken = document.querySelector('meta[name="csrf-token"]').content;

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrToken,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.ok) {
          toastr.clear();
          setTimeout(
            () =>
              toastr.success(
                `Votre abonnement à la Newsletter JPXMotors est maintenant confirmée.`
              ),
            300
          );
          return response.json();
        } else {
          setTimeout(() => toastr.error("Oups!"), 300);
          throw new Error("Network response was not ok.");
        }
      })
      .then(response => this.setState({subscription: response}))
      .catch(() => this.props.history.push("/decouvrir-nos-projets"));
  }

  render() {
    const {subscription} = this.state;

    return (
      <React.Fragment>
        <section className="hero is-medium is-primary banner-news">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-1 is-white" title="Newsletter JPXMotors!">
                Newsletter
              </h1>
            </div>
          </div>
        </section>

        <section className="hero is-medium is-ligth">
          <div className="hero-body">
            <div className="container has-text-centered">
              <div className="content">
                <h1 className="title is-2" title="Confirmation de votre email">
                  Confirmation de votre email
                </h1>
                <h2 className="subtitle is-4 pt-4">{subscription.email}</h2>

                <div className="content is-medium">
                  <p>Merci pour votre inscription à notre Newsletter.</p>
                  <p>
                    Maintenant que votre adresse e-mail est confirmée, vous
                    pourrez recevoir notre Newsletter.
                  </p>
                </div>

                <div className="has-text-centered pt-3">
                  <Link to="/" className="button is-primary is-medium">
                    Retour à la page d'accueil
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Suspense fallback={<Loader sectionName="small" />}>
          <Services />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Confirmation;