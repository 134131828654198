import React from "react";

export default () => (
  <React.Fragment>
    <section className="hero is-medium is-primary banner">
      <div className="hero-body">
        <div className="container">
          <div className="content">
            <h1>Tarifications</h1>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);