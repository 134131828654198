import React from "react";
import {Link} from "react-router-dom";

import {newsletterValidator} from "./../../validators";

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      email: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  onSubmit(event) {
    event.preventDefault();
    const url = "/api/v1/subscriptions/create";
    const {email} = this.state;

    if (email.length == 0) return;

    const body = {
      email
    };

    const token = document.querySelector('meta[name="csrf-token"]').content;

    toastr.options = {
      positionClass: "toast-top-full-width",
      hideDuration: 300,
      timeOut: 3000
    };

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        toastr.clear();
        if (response.ok) {
          setTimeout(
            () =>
              toastr.success(
                `Merci pour votre inscription à la Newsletter JPXMotors, un e-mail vous a été envoyé.`
              ),
            300
          );

          return response.json();
        } else {
          setTimeout(() => toastr.error("Oups!"), 300);
          throw new Error("Network response was not ok.");
        }
      })
      .then(response => this.setState({email: ""}))
      .then(response => {
        this.props.history.push(`/projets`);
      })
      .catch(error => console.log(error.message));
  }

  componentDidMount() {
    const url = "/api/v1/articles/lasted";
    const token = document.querySelector('meta[name="csrf-token"]').content;
    fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => this.setState({articles: response}))
      .catch(() => this.setState({articles: {}}));
  }

  render() {
    const {articles} = this.state;
    const allArticlesList = articles.map((article, index) => (
      <div key={index} className="column is-full-mobile is-full-tablet is-half">
        <div className="card">
          <div className="card-content">
            <p className="title is-5">{article.title}</p>
            <p className="subtitle is-6">{article.created}</p>

            <Link
              to={`/projet/${article.id}`}
              className="link is-primary has-text-weight-semibold"
            >
              Lire
            </Link>
          </div>
        </div>
      </div>
    ));

    const allArticles = (
      <section className="section has-background-light">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-full-mobile is-three-fifths">
              <div className="content is-medium">
                <h2
                  className="title is-2 is-primary mt-6 mb-5 has-text-centered"
                  title="Nos projets"
                >
                  Nos projets
                </h2>

                <div className="columns is-multiline">{allArticlesList}</div>
              </div>

              <div className="content is-medium">
                <h3
                  className="subtitle is-3 is-primary mt-4"
                  title="Recevez tous nos articles!"
                >
                  Recevez tous nos articles!
                </h3>
                <div className="card is-news">
                  <div className="card-content">
                    <h4
                      class="subtitle is-5 is-white mb-4"
                      title="Inscrivez-vous à la newsletter"
                    >
                      Inscrivez-vous à la newsletter
                    </h4>
                    <form onSubmit={this.onSubmit}>
                      <div className="columns is-multiline">
                        <div className="column is-full-mobile is-four-fifths">
                          <div className="field">
                            <div className="control has-icons-left has-icons-right">
                              <input
                                className="input is-light"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Saisissez votre e-mail..."
                                required
                                onChange={this.onChange}
                              />
                              <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="column is-full-mobile is-one-fifths">
                          <div className="control buttons">
                            <button type="submit" className="button is-light">
                              Je m'inscris
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <h4 className="subtitle is-4 is-light mt-4 mb-0">
                      Restez informés de nos dernières actualités, services et
                      produits.
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-full-mobile is-two-fifths has-text-right">
              <img alt="JPX Motors" src="/jpxmotors-news.svg" />
              <a
                className="is-size-7 is-italic is-family-sans-serif has-text-weight-light has-text-grey-lighter freepik-storyset"
                href="https://storyset.com/work"
                rel="noreferrer"
                target="_blank"
              >
                Illustration by Freepik Storyset
              </a>
            </div>
          </div>
        </div>
      </section>
    );
    const noArticle = "";

    return (
      <>
        <div className="">{articles.length > 0 ? allArticles : noArticle}</div>
      </>
    );
  }
}
export default Articles;
