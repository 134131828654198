import React from "react";

export default () => (
  <React.Fragment>
    <section className="section">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/192-maserati-back-brand.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img alt="JPX Motors" src="/android-chrome-192x192.png" width="100%" height="100%" />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Jeremy.P </p>
                    <p className="subtitle is-6">@jeremyp</p>
                  </div>
                </div>

                <div className="content">
                  L’univers Maserati, un mélange captivant de performances
                  à l'italienne.<a href="#">#italienne</a>
                  <br />
                  <time datetime="01-01-2021">
                    10:29 - 1 janvier 2021
                  </time>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-3by5">
                  <img alt="JPX Motors" src="/192-porshe-galery-pic11.jpg" />
                </figure>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/64-maserati-galery-pic-23.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img alt="JPX Motors" src="/android-chrome-192x192.png" width="100%" height="100%" />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Jeremy.P </p>
                    <p className="subtitle is-6">@jeremyp</p>
                  </div>
                </div>

                <div className="content">
                  Maserati qui repousse les frontières du temps.
                  <br />
                  <time datetime="01-03-2021">11:09 - 1 mars 2021</time>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/64-maserati-brand-front.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img alt="JPX Motors" src="/android-chrome-192x192.png" width="100%" height="100%" />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Jeremy.P </p>
                    <p className="subtitle is-6">@jeremyp</p>
                  </div>
                </div>

                <div className="content">
                  L'association redoutable des meilleures performances,
                  vous êtes tout sauf ordinaire.<a href="#">#italienne</a>
                  <a href="#">#puissance</a>
                  <br />
                  <time datetime="01-01-2021">
                    10:29 - 1 janvier 2021
                  </time>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-3by5">
                  <img alt="JPX Motors" src="/64-interieur-porshe.jpg" />
                </figure>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/192-maserati-banner.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img alt="JPX Motors" src="/android-chrome-192x192.png" width="100%" height="100%" />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Jeremy.P </p>
                    <p className="subtitle is-6">@jeremyp</p>
                  </div>
                </div>

                <div className="content">
                  <a href="#">#maserati</a> <a href="#">#italienne</a>{" "}
                  <a href="#">#puissance</a>
                  <br />
                  <time datetime="07-07-2021">
                    23:20 - 7 juillet 2021
                  </time>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-3by5">
                  <img alt="JPX Motors" src="/192-porshe-galery-pic1.jpg" />
                </figure>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/128-ferrari-galery-pic1.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img alt="JPX Motors" src="/android-chrome-192x192.png" width="100%" height="100%" />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Jeremy.P </p>
                    <p className="subtitle is-6">@jeremyp</p>
                  </div>
                </div>

                <div className="content">
                  <a href="#">#ferrari</a>
                  <br />
                  <time datetime="26-08-2021">09:30 - 26 aôut 2021</time>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);