import React from "react";
import {animated, useSpring} from "react-spring";

export default function Confiance() {
  const propsFadeIn = useSpring({
    config: {duration: 500},
    opacity: 1,
    from: {opacity: 0}
  });

  return (
    <React.Fragment>
      <animated.div style={propsFadeIn}>
        <div className="content is-medium">
          <div className="columns is-multiline">
            <div className="column is-full">
              <h3
                className="subtitle has-text-centered mb-6 is-logo"
                title="JPX Motors"
              >
                JPXMotors
              </h3>
            </div>

            <div className="column is-full-mobile is-half">
              <p className="has-text-justified">
                <strong>
                  <span className="is-logo">JPXMotors</span>
                </strong>
                &nbsp;vous souhaite la bienvenue!​
              </p>

              <p className="">
                Vous souhaitez
                <strong> améliorer </strong>
                vos process d’organisation d’AG.
              </p>
              <p className="">
                Notre plateforme vous aidera dans votre transformation
                numérique.​
              </p>
            </div>

            <div className="column is-full-mobile is-half ">
              <div className="">
                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-32x32">
                        <i className="fa fa-building icon is-medium fas fa-2x is-primary"></i>
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-4">Gain pour l’entreprise</p>
                      <p className="subtitle is-6">
                        Administrer avec sérénité vos assemblées générales.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-32x32">
                      <i className="fa fa-handshake icon is-medium fas fa-2x is-primary"></i>
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Gain pour les actionnaires</p>
                    <p className="subtitle is-6">
                      Vos envois sensibles sécurisés.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </React.Fragment>
  );
}
