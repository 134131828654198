import React from "react";
const myImg = require("../../../assets/images/pictures/IMG_4887.JPG");

export default () => (
  <React.Fragment>
    <section className="section">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-three-fifths">
            <div className="content is-medium">
              <h2 className="title is-3 mt-6 mb-3" title="Entretiens">
                Entretiens
              </h2>

              <p className="has-text-justified">
                <ul>
                  <li>Remplacement des fluides & Consommables</li>
                  <li>Distributions</li>
                  <li>Embrayage et transmission</li>
                  <li>Boite F1 et paramétrage de Grille</li>
                  <li>Suspension et Freinage</li>
                  <li>Électricité courante</li>
                  <li>Entretien courant</li>
                  <li>Remise à niveau et fiabilisation</li>
                  <li>Révision</li>
                  <li>Restauration</li>
                  <li>Rénovation des amortisseurs KONI</li>
                  <li>Fiabilisation électrique</li>
                  <li>
                    Réfection/échange standard des pompes à eau de Biturbo &
                    3200 GT
                  </li>
                </ul>
              </p>
            </div>
          </div>

          <div className="column is-full-mobile is-two-fifths has-text-right">
            <img alt="JPXMotors" src={myImg} width="100%" height="100%" />
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);