import React from "react";
import {Link} from "react-router-dom";

export default () => (
  <section className="section is-medium has-background-light">
    <div className="container">
      <div className="columns is-multiline is-centered">
        <div className="column is-full">
          <h2 className="title is-3">Le contenu de cette page est vide</h2>
          <Link
            to={`/`}
            className="button is-medium is-size-6-mobile is-size-6-tablet"
          >
            Retourner à l'accueil
          </Link>
        </div>
        <div className="column is-full-mobile is-half has-text-right">
          <img alt="JPXMotors" src="/jpxmotors-no-data-m.svg" />
          <a
            className="is-size-7 is-italic is-family-sans-serif has-text-weight-light has-text-grey-lighter freepik-storyset"
            href="https://storyset.com/work"
            rel="noreferrer"
            target="_blank"
          >
            Illustration by Freepik Storyset
          </a>
        </div>
      </div>
    </div>
  </section>
);
