import React from "react";
import {Link} from "react-router-dom";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: {email: "", abo: true, otherAbo: true}
    };
  }

  componentDidMount() {
    const {
      match: {
        params: {token}
      }
    } = this.props;

    const url = `/api/v1/subscriptions/check_abonnement/${token}`;
    const csrToken = document.querySelector('meta[name="csrf-token"]').content;

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrToken,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => this.setState({subscription: response}))
      .catch(() => this.props.history.push("/decouvrir-nos-projets"));
  }

  render() {
    const {subscription} = this.state;

    return (
      <div className="">
        <section className="hero is-fullheight is-ligth">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title">
                Votre abonnement à la Newsletter de{" "}
                <span className="is-logo">JPXMotors</span>
              </h1>
              <h2 className="subtitle is-3 pt-2">{subscription.email}</h2>
            </div>
          </div>
        </section>

        <section className="section is-large">
          <div className="container">
            <div className="has-text-centered">
              <Link to="/" className="button is-primary is-medium">
                Retour à la page d'accueil
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Subscription;
