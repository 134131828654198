import React, {useState} from "react";

import Entreprise from "./Entreprise";
import Gestionnaire from "./Gestionnaire";
import Collectivite from "./Collectivite";
import Confiance from "./Confiance";

export default function Tabs() {
  const [tab, setTab] = useState("gestionnaire");

  const displayTabContent = () => {
    switch (tab) {
      case "gestionnaire":
        return <Gestionnaire />;

      case "confiance":
        return <Confiance />;

      case "collectivite":
        return <Collectivite />;

      case "entreprise":
      default:
        return <Entreprise />;
    }
  };

  const goGestionnaire = () => {
    setTab("gestionnaire");
  };

  const goConfiance = () => {
    setTab("confiance");
  };

  const goCollectivite = () => {
    setTab("collectivite");
  };

  const goEntreprise = () => {
    setTab("entreprise");
  };

  return (
    <section className="section has-background-light">
      <div className="container pb-3">
        <h2
          className="title is-2 is-size-3-mobile has-text-centered mb-6"
          title="Lancez votre transformation numérique"
        >
          Lancez votre transformation numérique
        </h2>
        <div className="columns is-mobile">
          <div className="column is-full card">
            <div className="tabs is-large is-centered">
              <ul>
                <li
                  onClick={goEntreprise}
                  className={`is-carrot ${
                    tab === "entreprise" ? "is-active" : ""
                  }`}
                >
                  <a href="#goEntreprise" className="is-carrot">
                    Entreprise
                  </a>
                </li>
                <li
                  onClick={goGestionnaire}
                  className={`is-pumpkin ${
                    tab === "gestionnaire" ? "is-active" : ""
                  }`}
                >
                  <a href="#goGestionnaire" className="is-pumpkin">
                    Gestionnaire immobilier
                  </a>
                </li>
                <li
                  onClick={goConfiance}
                  className={`is-primary ${
                    tab === "confiance" ? "is-active" : ""
                  }`}
                >
                  <a href="#goConfiance" className="is-primary">
                    Tiers de confiance
                  </a>
                </li>
                <li
                  onClick={goCollectivite}
                  className={`is-pomegranate ${
                    tab === "collectivite" ? "is-active" : ""
                  }`}
                >
                  <a href="#goCollectivite" className="is-pomegranate">
                    Collectivité
                  </a>
                </li>
              </ul>
            </div>
            <div className="section">{displayTabContent()}</div>
          </div>
        </div>
      </div>
    </section>
  );
}
