import React, {lazy} from "react";
import {Switch, Route} from "react-router-dom";
import {AnimatedSwitch} from "react-router-transition";

const Home = lazy(() => import("../components/Home"));
const Articles = lazy(() => import("../components/Articles"));
const Article = lazy(() => import("../components/Articles/Article"));
const Posts = lazy(() => import("../components/Posts"));
const Post = lazy(() => import("../components/Posts/Post"));
const OurServices = lazy(() => import("../components/OurServices"));
const About = lazy(() => import("../components/About"));
const Pricing = lazy(() => import("../components/Pricing"));
const Discover = lazy(() => import("../components/Discover"));
const Sales = lazy(() => import("../components/Sales"));
const ItemSale = lazy(() => import("../components/Sales/ItemSale"));
const Subscription = lazy(() => import("../components/Subscription"));
const ContactUs = lazy(() => import("../components/Contact"));
const Cgu = lazy(() => import("../components/Legales/Cgu"));
const Cgv = lazy(() => import("../components/Legales/Cgv"));
const Mentions = lazy(() => import("../components/Legales/Mentions"));
const Confidentialite = lazy(() =>
  import("../components/Legales/Confidentialite")
);
const SubscriptionConfirmation = lazy(() =>
  import("../components/Subscription/Confirmation")
);
const SubscriptionUnsub = lazy(() =>
  import("../components/Subscription/Unsub")
);

const routes = [
  { path: "/", exact: true, component: Home },
  {
    path: "/subscription/confirmation/:token",
    exact: true,
    component: SubscriptionConfirmation,
  },
  {
    path: "/subscription/unsub/:token",
    exact: true,
    component: SubscriptionUnsub,
  },
  { path: "/subscription/:token", exact: true, component: Subscription },

  { path: "/decouvrir-nos-projets", exact: true, component: Articles },
  { path: "/nos-projets", exact: true, component: Articles },
  { path: "/projet/:id", exact: true, component: Article },
  { path: "/posts", exact: true, component: Posts },
  { path: "/post/:id", exact: true, component: Post },

  { path: "/contactez-nous", exact: true, component: ContactUs },
  { path: "/nos-services", exact: true, component: OurServices },
  { path: "/a-propos", exact: true, component: About },
  { path: "/a-vendre", exact: true, component: Sales },
  { path: "/item/:id", exact: true, component: ItemSale },
  { path: "/nos-offres", exact: true, component: Pricing },

  { path: "/conditions-generales-utilisations", exact: true, component: Cgu },
  { path: "/conditions-generales-de-ventes", exact: true, component: Cgv },
  { path: "/mentions-legales", exact: true, component: Mentions },
  {
    path: "/politique-de-confidentialite",
    exact: true,
    component: Confidentialite,
  },
  {
    path: "/*",
    component: Home,
  },
];

class Navigation extends React.Component {
  render() {
    return (
      <Switch>
        <AnimatedSwitch
          atEnter={{
            opacity: 1
          }}
          atLeave={{
            opacity: 1
          }}
          atActive={{
            opacity: 1
          }}
          className="switch-wrapper"
        >
          {routes.map(route => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </AnimatedSwitch>
      </Switch>
    );
  }
}

export default Navigation;