import React from "react";
import { Link } from "react-router-dom";

import { TwitterShareButton  } from "react-twitter-embed";

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = { post: { title: "", src: "", content: "" } };

    this.addHtmlEntities = this.addHtmlEntities.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const url = `/api/v1/posts/show/${id}`;

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((response) => this.setState({ post: response }))
      .catch(() => this.props.history.push("/posts"));
  }

  addHtmlEntities(str) {
    return String(str)
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }

  render() {
    const { post } = this.state;

    const postContent = this.addHtmlEntities(post.content);

    return (
      <div className="">
        <section className="hero is-medium is-primary banner">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">{post.title}</h1>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <h1 className="title">{post.title}</h1>
            <div className="content">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${postContent}`,
                }}
              />

              <TwitterShareButton
                url="https://jpxmotors.fr/post/{}"
                options={{
                  text: "#reactjs is awesome",
                  via: "saurabhnemade",
                  screenName: null,
                }}
              />
              <TwitterShareButton
                url="https://jpxmotors.fr/post/{}"
                options={{
                  text: "#reactjs is awesome",
                  via: "saurabhnemade",
                  size: "large",
                }}
              />
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="buttons">
              <Link to="/posts" className="button is-default">
                Retour à la liste
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Post;
