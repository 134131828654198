import React from "react";
import {Link} from "react-router-dom";

export default () => (
  <section className="section is-medium banner-newsletter">
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-full-mobile is-half">
          <div className="content">
            <h2 className="title is-3 is-white title-logo">
              JPXMotors
            </h2>
            <h3 className="subtitle is-5 is-light">
              Spécialiste dans l’entretien, la réparation et la restauration de Maserati!
            </h3>
          </div>
        </div>
        <div className="column is-full-mobile is-half has-background-light">
          <div className="content has-text-centered pt-3">
            <h2 className="title is-4 is-size-3-mobile is-primary my-2" title="Les Anciennes et modernes s’y croisent depuis 15 ans.">
              Les Anciennes et modernes s’y croisent depuis 15 ans.
            </h2>

            <h3 className="subtitle is-5 is-size-4-mobile is-light mt-2 mb-4" title="Des services proposés dans une singulière et discrète officine et la garantie d'un plaisir d’utilisation de tous les instants.">
              Des services proposés dans une singulière et discrète officine et la garantie d'un plaisir d’utilisation de tous les instants.
            </h3>

            <div className="has-text-centered">
              <Link to={`/contactez-nous`} className="button is-primary is-medium">
                Je souhaite un devis
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
