import React, {useState, useCallback} from "react";
import {useTransition, animated} from "react-spring";
import "./SwitchTools.css";

const pages = [
  ({style}) => (
    <animated.div style={{...style, background: "darkorange"}}>
      Un gestionnaire immobilier
    </animated.div>
  ),
  ({style}) => (
    <animated.div style={{...style, background: "tomato"}}>
      Une entreprise
    </animated.div>
  ),
  ({style}) => (
    <animated.div style={{...style, background: "midnightblue"}}>
      Un tiers de confiance
    </animated.div>
  ),
  ({style}) => (
    <animated.div style={{...style, background: "orangered"}}>
      Une collectivité
    </animated.div>
  )
];

export default function SwitchTools() {
  const [index, set] = useState(0);
  const onClick = useCallback(() => set(state => (state + 1) % 4), []);
  const transitionsSlide = useTransition(index, p => p, {
    from: {opacity: 0, transform: "translate3d(100%,0,0)"},
    enter: {opacity: 1, transform: "translate3d(0%,0,0)"},
    leave: {opacity: 0, transform: "translate3d(-50%,0,0)"}
  });
  return (
    <section className="section">
      <div className="container">
        <div className="simple-trans-main" onClick={onClick}>
          {transitionsSlide.map(({item, props, key}) => {
            const Page = pages[item];
            return <Page key={key} style={props} />;
          })}
        </div>
      </div>
    </section>
  );
}
