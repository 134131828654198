import React from "react";
import {Link} from "react-router-dom";

import NewSubscription from "./../Subscription/New";

class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {article: {title: "", content: ""}};

    this.addHtmlEntities = this.addHtmlEntities.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: {id}
      }
    } = this.props;

    const url = `/api/v1/articles/show/${id}`;

    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => this.setState({article: response}))
      .catch(() => this.props.history.push("/decouvrir-nos-projets"));
  }

  addHtmlEntities(str) {
    return String(str)
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }

  render() {
    const {article} = this.state;

    const articleContent = this.addHtmlEntities(article.content);

    return (
      <div className="">
        <section className="hero is-medium is-primary banner">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-1 is-size-2-mobile is-white my-6 is-logo">
                {article.title}
              </h1>
              <h2 className="subtitle is-3">{article.subtitle}</h2>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <h2 className="title is-2">{article.title}</h2>
            <h3 className="subtitle is-4">{article.subtitle}</h3>
            <div className="content">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${articleContent}`,
                }}
              />
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="buttons">
              <Link to="/decouvrir-nos-projets" className="button is-primary">
                Retour à la liste
              </Link>
            </div>
          </div>
        </section>
        <NewSubscription />
      </div>
    );
  }
}

export default Article;
