import React from "react";
import {Link} from "react-router-dom";

import NewSubscription from "../Subscription/New";

class ItemSale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {itemSale: {title: "", content: ""}};

    this.addHtmlEntities = this.addHtmlEntities.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: {id}
      }
    } = this.props;

    const url = `/api/v1/cars/show/${id}`;

    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => this.setState({itemSale: response}))
      .catch(() => this.props.history.push("/decouvrir-nos-projets"));
  }

  addHtmlEntities(str) {
    return String(str)
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }

  render() {
    const {itemSale} = this.state;

    const itemSaleContent = this.addHtmlEntities(itemSale.description);

    return (
      <div className="">
        <section className="hero is-medium is-primary banner">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title is-1 is-size-2-mobile is-white my-6 is-logo">
                {itemSale.brand}
              </h1>
              <h2 className="subtitle is-3">
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(itemSale.price)}
              </h2>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <h2 className="title is-2">{itemSale.brand}</h2>
            <h3 className="subtitle is-4">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(itemSale.price)}
            </h3>
            <div className="block">
              <ul className="">
                {!!itemSale.years_prod && (
                  <li>Année de production : {itemSale.years_prod}</li>
                )}
                {!!itemSale.release && (
                  <li>Mise en circulation : {itemSale.release}</li>
                )}
                {!!itemSale.ct && (
                  <li>Contrôle technique : {itemSale.ct}</li>
                )}
                {!!itemSale.energy && (
                  <li>Energie : {itemSale.energy}</li>
                )}
                {!!itemSale.gearbox && (
                  <li>Boîte de vitesse : {itemSale.gearbox}</li>
                )}
                {!!itemSale.km_counter && (
                  <li>Kilométrage au compteur : {itemSale.km_counter}</li>
                )}
                {!!itemSale.color_ext && (
                  <li>Couleur extérieure : {itemSale.color_ext}</li>
                )}
                {!!itemSale.color_in && (
                  <li>Couleur intérieure : {itemSale.color_in}</li>
                )}
                {!!itemSale.nb_doors && (
                  <li>Nombre de portes : {itemSale.nb_doors}</li>
                )}
                {!!itemSale.nb_places && (
                  <li>Nombre de places : {itemSale.nb_places}</li>
                )}
              </ul>
            </div>
            <div className="content">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${itemSaleContent}`,
                }}
              />
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="buttons">
              <Link to="/a-vendre" className="button is-primary">
                Retour à la liste des voitures
              </Link>
            </div>
          </div>
        </section>
        <NewSubscription />
      </div>
    );
  }
}

export default ItemSale;