import React from "react";
import "./card.scss";

import { Link } from "react-router-dom";

export default function Services() {
  return (
    <section className="section has-background-light">
      <div className="container">
        <div className="content has-text-centered">
          <h2 className="title is-2 is-primary is-logo" title="Nos services">
            Nos services
          </h2>
        </div>

        <div className="columns is-multiline">
          <div className="column is-full-mobile is-half-tablet is-half">
            <div className="container-card">
              <div className="card-ya card-ya-entretien">
                <h2 className="title">Entretien & Réparation</h2>

                <div className="pic"></div>
                <div className="social"></div>
                <Link to={`/nos-services`}>
                  <button></button>
                </Link>
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-half-tablet is-half">
            <div className="container-card">
              <div className="card-ya card-ya-reparation">
                <h2 className="title">Restauration lourde</h2>

                <div className="pic"></div>

                <div className="social"></div>
                <Link to={`/nos-services`}>
                  <button></button>
                </Link>
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-half-tablet is-half">
            <div className="container-card">
              <div className="card-ya card-ya-diagnostique">
                <h2 className="title">Diagnostique & Recherche</h2>

                <div className="pic"></div>

                <div className="social"></div>
                <Link to={`/nos-services`}>
                  <button></button>
                </Link>
              </div>
            </div>
          </div>
          <div className="column is-full-mobile is-half-tablet is-half">
            <div className="container-card">
              <div className="card-ya card-ya-modelisation">
                <h2 className="title">Modélisation & Fabrication</h2>

                <div className="pic"></div>

                <div className="social"></div>
                <Link to={`/nos-services`}>
                  <button></button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}