import React from "react";

export default () => (
  <React.Fragment>
    <section className="section is-medium">
      <h2 className="title is-1 is-light has-text-centered">
        Spécialiste dans l’entretien, la réparation et la restauration de
        Maserati!
      </h2>
      <div className="columns is-mobile is-multiline is-centered">
        <div className="column is-narrow">
          <figure className="image is-92x92 is-vcentered  has-text-centered">
            <img alt="JPX Motors" src="/jpx_motors_red_logo.png" />
          </figure>
        </div>
      </div>
    </section>
  </React.Fragment>
);
