import React from "react";
import {
  FaBeer,
  FaBlackTie,
  FaEnvelopeOpenText,
  FaVoteYea,
  FaUserTie
} from "react-icons/fa";

export default function TimeLineBulma() {
  return (
    <section className="section has-background-light">
      <h2 className="title is-3 is-primary has-text-centered">
        Déroulement d'une Réparation
      </h2>
      <div className="timeline is-centered">
        <header className="timeline-header">
          <span className="tag is-medium is-primary">
            Réception du véhicule
          </span>
        </header>
        <div className="timeline-item is-primary">
          <div className="timeline-marker is-primary is-icon">
            <i className="fa fa-key"></i>
          </div>
          <div className="timeline-content">
            <div className="is-hidden-tablet">
              <h3 className="vertical-timeline-element-title title is-5 is-primary">
                ...
              </h3>

              <h4 className="vertical-timeline-element-subtitle subtitle is-6 is-light">
                ...
              </h4>
            </div>
            <div className=" is-hidden-mobile">
              <div className="card">
                <div className="card-content">
                  <h3 className="vertical-timeline-element-title title is-5 is-primary">
                    ...
                  </h3>

                  <h4 className="vertical-timeline-element-subtitle subtitle is-6 is-light">
                    ...
                  </h4>
                  <ul>
                    <li>
                      ...
                    </li>
                    <li>
                      ...
                    </li>
                    <li>
                      ...
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="timeline-item is-primary">
          <div className="timeline-marker is-primary is-icon">
            <i className="fa fa-cog"></i>
          </div>
          <div className="timeline-content">
            <div className="is-hidden-tablet">
              <h3 className="vertical-timeline-element-title title is-5 is-primary">
                ...
              </h3>
              <h4 className="vertical-timeline-element-subtitle subtitle is-6 is-light">
                ...
              </h4>

              <ul>
                <li>...</li>
                <li>
                  ...
                </li>
                <li>
                  ...
                </li>
              </ul>
            </div>
            <div className="is-hidden-mobile">
              <div className="card">
                <div className="card-content">
                  <h3 className="vertical-timeline-element-title title is-5 is-primary">
                    ...
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle subtitle is-6 is-light">
                    ...
                  </h4>
                  <ul>
                    <li>
                      ...
                    </li>
                    <li>
                      ...
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="timeline-header">
          <span className="tag is-primary">...</span>
        </header>
        <div className="timeline-item is-primary">
          <div className="timeline-marker is-primary is-icon">
            <i className="fa fa-cog"></i>
          </div>
          <div className="timeline-content">
            <div className="is-hidden-tablet">
              ...
            </div>
            <div className="is-hidden-mobile">
              <div className="card">
                <div className="card-content">
                  ...
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="timeline-item is-primary">
          <div className="timeline-marker is-primary is-icon">
            <i className="fa fa-cog"></i>
          </div>
          <div className="timeline-content">
            <div className="is-hidden-tablet">
              <h3 className="vertical-timeline-element-title title is-5 is-primary">
                ...
              </h3>
              <h4 className="vertical-timeline-element-subtitle subtitle is-6 is-light">
                ...
              </h4>
            </div>
            <div className="is-hidden-mobile">
              <div className="card">
                <div className="card-content">
                  <h3 className="vertical-timeline-element-title title is-5 is-primary">
                    ...
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle subtitle is-6 is-light">
                    ...
                  </h4>
                  <ul>
                    <li>...</li>
                    <li>...</li>
                    <li>...</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="timeline-header">
          <span className="tag is-primary">Le jour J</span>
        </header>
        <div className="timeline-item is-primary">
          <div className="timeline-marker is-primary is-icon">
            <i className="fa fa-car"></i>
          </div>
          <div className="timeline-content">
            <div className="is-hidden-tablet">
              <h3 className="vertical-timeline-element-title title is-5 is-primary">
                ...
              </h3>
              <h4 className="vertical-timeline-element-subtitle subtitle is-6 is-light">
                ...
              </h4>
            </div>
            <div className="is-hidden-mobile">
              <div className="card">
                <div className="card-content">
                  <h3 className="vertical-timeline-element-title title is-5 is-primary">
                    ...
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle subtitle is-6 is-light">
                    ...
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="timeline-header">
          <span className="tag is-medium is-primary">
            Profiter en tous instant
          </span>
        </header>
      </div>
    </section>
  );
}