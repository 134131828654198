import React, {Suspense, lazy} from "react";
import {Link} from "react-router-dom";

import Loader from "../Loader";

const NoData = lazy(() => import("../Loader/NoData"));
const NewSubscription = lazy(() => import("./../Subscription/New"));

export default class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: []
    };
  }

  componentDidMount() {
    const url = "/api/v1/articles/index";

    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => this.setState({articles: response}))
      .catch(() => this.props.history.push("/"));
  }

  render() {
    const {articles} = this.state;
    const allArticlesList = articles.map((article, index) => (
      <div key={index} className="column is-full">
        <div className="card">
          <div className="card-content">
            <div className="content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-5">{article.title}</p>
                  <p className="subtitle is-6">{article.created}</p>
                </div>
              </div>

              <div className="content buttons has-text-centered">
                <Link
                  to={`/projet/${article.id}`}
                  className="button is-primary"
                >
                  Lire
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    const allArticles = (
      <section className="section has-background-light">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-full-mobile is-three-fifth">
              <div className="columns is-multiline">{allArticlesList}</div>
            </div>

            <div className="column is-full-mobile is-two-fifths has-text-right">
              <img alt="JPX Motors" src="/jpxmotors-car-amico.svg" />
              <a
                className="is-size-7 is-italic is-family-sans-serif has-text-weight-light has-text-grey-lighter freepik-storyset"
                href="https://storyset.com/work"
                rel="noreferrer"
                target="_blank"
              >
                Illustration by Freepik Storyset
              </a>
            </div>
          </div>
        </div>
      </section>
    );
    const noArticle = <NoData />;

    return (
      <React.Fragment>
        <section className="hero is-medium is-primary banner">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1
                className="title is-1 is-size-2-mobile is-white my-6 is-logo"
                title="Découvrir nos  projets"
              >
                Découvrir nos projets
              </h1>
            </div>
          </div>
        </section>

        {articles.length > 0 ? allArticles : noArticle}

        <Suspense fallback={<Loader sectionName="small" />}>
          <NewSubscription />
        </Suspense>
      </React.Fragment>
    );
  }
}