import React, { Suspense, lazy } from "react";
import InstagramEmbed from "react-instagram-embed";
import { Link } from "react-router-dom";
import { Form, Text } from "informed";

import Loader from "../Loader";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Services = lazy(() => import("./Services"));
const Contact = lazy(() => import("../Contact/Banner"));

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      email: null,
      loading: false,
      response: null,
    };

    this.onChange = this.onChange.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  validateField = (value) => {
    if (!value || value.trim() === "") {
      toastr.clear();
      setTimeout(
        () =>
          toastr.error("Oups! Vous devez compléter le champ avec votre email."),
        300
      );
      return "Vous devez compléter le champ avec votre email.";
    }
  };

  submit = (values) => {
    this.setState({ loading: true });
    const url = "/api/v1/subscriptions/create";
    const email = values.email;

    if (email.length == 0) {
      toastr.clear();
      setTimeout(
        () =>
          toastr.error("Oups! Vous devez compléter le champ avec votre email."),
        300
      );
      return;
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    toastr.options = {
      positionClass: "toast-top-full-width",
      hideDuration: 300,
      timeOut: 3000,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        toastr.clear();

        if (response.ok) {
          setTimeout(
            () =>
              toastr.success(
                `Merci pour votre inscription à la Newsletter JPXMotors, un e-mail vous a été envoyé.`
              ),
            300
          );

          this.setState({ email: "" });
          this.props.history.push(`/nos-projets`);

          return response.json();
        } else {
          setTimeout(() => toastr.error("Oups!"), 300);
          throw new Error("Network response was not ok.");
        }
      })
      .then((response) => {
        this.setState({ loading: false });
      })
      .catch((error) => console.log(error.message));
  };

  componentDidMount() {
    const url = "/api/v1/articles/lasted";
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((response) => this.setState({ articles: response }))
      .catch(() => this.setState({ articles: {} }));
  }

  render() {
    const { articles } = this.state;
    const allArticlesList = articles.map((article, index) => (
      <div key={index} className="column is-full-mobile is-full-tablet is-half">
        <div className="card">
          <div className="card-content">
            <p className="title is-5">{article.title}</p>
            <p className="subtitle is-6">{article.created}</p>

            <Link
              to={`/projet/${article.id}`}
              className="link is-primary has-text-weight-semibold"
            >
              Lire
            </Link>
          </div>
        </div>
      </div>
    ));

    const allArticles = (
      <section className="section has-background-light">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-full-mobile is-three-fifths">
              <div className="content is-medium">
                <Link to={`/projets`} className="link is-primary">
                  <h2
                    className="title is-2 is-primary mt-6 mb-5 is-logo has-text-centered"
                    title="Nos projets"
                  >
                    Nos projets
                  </h2>
                </Link>

                <div className="columns is-multiline">{allArticlesList}</div>
              </div>

              <div className="content is-medium">
                <h3
                  className="subtitle is-3 is-primary mt-4"
                  title="Inscrivez-vous à la newsletter"
                >
                  Inscrivez-vous à la newsletter
                </h3>
                <div className="card is-news">
                  <div className="card-content">
                    <Form onSubmit={this.submit}>
                      <div className="columns is-multiline">
                        <div className="column is-full-mobile is-four-fifths">
                          <div className="field">
                            <div className="control has-icons-left has-icons-right">
                              <Text
                                className="input is-light"
                                type="email"
                                field="email"
                                id="email"
                                initialValue={this.state.email}
                                placeholder="Saisissez votre e-mail..."
                                required
                                onChange={this.onChange}
                                validate={this.validateField}
                              />
                              <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="column is-full-mobile is-one-fifths">
                          <div className="control buttons">
                            <button
                              type="submit"
                              className="button is-light"
                              disabled={this.state.loading}
                            >
                              {this.state.loading
                                ? "Demande en cours de traitement..."
                                : "Je m'inscris"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-full-mobile is-two-fifths has-text-right">
              <img alt="JPX Motors" src="/jpxmotors-news.svg" />
              <a
                className="is-size-7 is-italic is-family-sans-serif has-text-weight-light has-text-grey-lighter freepik-storyset"
                href="https://storyset.com/work"
                rel="noreferrer"
                target="_blank"
              >
                Illustration by Freepik Storyset
              </a>
            </div>
          </div>
        </div>
      </section>
    );
    const noArticle = "";

    return (
      <React.Fragment>
        <section className="hero is-large is-primary banner">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-multiline">
                <div className="column is-full-mobile is-half">
                  <div className="content is-medium">
                    <h2 className="title is-3 is-size-2-mobile my-6 is-logo">
                      JPXMotors
                    </h2>
                    <p className="has-text-justified">
                      Société spécialisée dans l’entretien, le diagnostique, la
                      réparation & la restauration de <strong>MASERATI</strong>,
                      de toutes générations (60’ à nos jours).
                    </p>
                    <p className="has-text-justified">
                      Elle est basée dans le Vexin dans l’atypique ville de
                      Marines à 35 minutes de L’ouest Parisien.
                    </p>
                    <p className="has-text-justified">
                      Elle s’appuie sur mon expertise, ayant passé plus d’une
                      vingtaine d’années dans l’industrie Aéronautique. J'ai été
                      formé sur des technologies de pointes et des mécaniques
                      complexes (Rafale et Mirage 2000...).
                    </p>
                    <p className="has-text-justified">
                      J'ai depuis longtemps transposé ces techniques au service
                      de ma passion automobile.
                    </p>
                    <p className="has-text-justified">
                      Tombé sous le charme des{" "}
                      <strong>Mécaniques Italiennes</strong>, J'ai depuis 15 ans
                      jeté mon dévolue sur la firme au Trident.
                    </p>
                    <p className="has-text-justified">
                      La Genèse des <strong>Biturbo</strong> n’ayant plus de
                      secret, l’histoire de cette firme m'a poussée à découvrir
                      l’intégralité de la gamme de la création à nos jours.
                    </p>
                    <p className="has-text-justified">
                      Je propose désormais mes services dans une singulière et
                      discrète officine.
                    </p>
                    <h3 className="subtitle is-4 my-4 is-primary">
                      Les Anciennes et modernes se croisent avec une seule
                      volonté pour{" "}
                      <strong className="is-logo is-primary">JPXMotors</strong>,
                      vous garantir un plaisir d’utilisation de tous les
                      instants...
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Suspense fallback={<Loader sectionName="small" />}>
          <Services />
        </Suspense>

        <div className="">{articles.length > 0 ? allArticles : noArticle}</div>

        <Suspense fallback={<Loader sectionName="small" />}>
          <Contact />
        </Suspense>

        <Suspense fallback={<Loader sectionName="small" />}>
          <InstagramEmbed
            url="https://instagr.am/p/jpxmotors/"
            clientAccessToken="123|456"
            maxWidth={320}
            hideCaption={false}
            containerTagName="div"
            protocol=""
            injectScript
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
          />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Home;