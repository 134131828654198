import React from "react";
import {Link} from "react-router-dom";

import {observer, inject} from "mobx-react";

@inject("routing")
@observer
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.routing = props.routing;
    this.state = {
      header: null
    };
  }

  componentDidMount() {
    this.setState(
      {
        header: document.querySelector(".navbar")
      },
      () => {
        this.watchLocation(this.routing.location);
        this.watchScroll();
      }
    );

    try {
      const $navbarBurger = document.querySelector(".navbar-burger");
      const $navbarMenu = document.querySelector(".navbar-menu");
      const $navbarItems = document.querySelectorAll(".navbar-action");

      $navbarBurger.addEventListener("click", event => {
        console.log(event);

        $navbarBurger.classList.toggle("is-active");
        $navbarMenu.classList.toggle("is-active");
      });
      $navbarItems.forEach(el => {
        el.addEventListener("click", event => {
          console.log(event);

          $navbarBurger.classList.toggle("is-active");
          $navbarMenu.classList.toggle("is-active");
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  checkScroll = () => {
    if (document.documentElement.scrollTop > 105) {
      this.state.header.classList.add("alt");
    } else {
      this.state.header.classList.remove("alt");
    }
  };

  watchScroll = () => {
    this.routing.history.listen(location => {
      this.watchLocation(location);
    });
  };

  watchLocation = location => {
    switch (location.pathname) {
      case "/":
        document.onscroll = this.checkScroll;
        this.checkScroll();
        break;

      default:
        document.onscroll = null;
        this.state.header.classList.add("alt");
    }
  };

  render() {
    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to={`/`} className="navbar-item">
            <img
              alt="JPX Motors"
              src="/jpx_motors_red_logo.png"
              width=""
              height="100%"
            />
          </Link>

          <Link
            to={`#`}
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarHeader"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </Link>
        </div>

        <div id="navbarHeader" className="navbar-menu">
          <div className="navbar-start">
            <Link to={`/nos-services`} className="navbar-item is-hoverable">
              Nos services
            </Link>

            <Link
              to={`/decouvrir-nos-projets`}
              className="navbar-item is-hoverable"
            >
              Découvrir nos projets
            </Link>

            <Link to={`/a-vendre`} className="navbar-item">
              A vendre
            </Link>
          </div>

          <div className="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <Link to={`/contactez-nous`} className="button is-light">
                  <span className="is-primary">Contactez-nous</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
