import React from "react";

import {observer, inject} from "mobx-react";

@inject("routing")
@observer
class NewSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  onSubmit(event) {
    event.preventDefault();
    const url = "/api/v1/subscriptions/create";

    const {push} = this.props.routing;
    const {email} = this.state;

    if (email.length == 0) return;

    const body = {
      email
    };

    const token = document.querySelector('meta[name="csrf-token"]').content;
    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        this.setState({email: ""});
        if (response.ok) {
          toastr.options = {
            positionClass: "toast-top-full-width",
            hideDuration: 300,
            timeOut: 3000
          };
          toastr.clear();
          setTimeout(
            () =>
              toastr.success(
                `Merci pour votre inscription, un email vous a été envoyé.`
              ),
            300
          );

          return response.json();
        } else {
          toastr.options = {
            positionClass: "toast-top-full-width",
            hideDuration: 300,
            timeOut: 3000
          };
          toastr.clear();
          setTimeout(() => toastr.error("Oups!"), 300);
          throw new Error("Network response was not ok.");
        }
      })
      .then(response => this.setState({email: ""}))
      .then(response => {
        push(`/`);
      })
      .catch(error => console.log(error.message));
  }

  render() {
    return (
      <div className="">
        <section className="section is-medium banner-news">
          <div className="container">
            <div className="columns is-multiline">
              <div className="column is-full-mobile is-half">
                <div className="content">
                  <h2
                    className="title is-3 is-white"
                    title="Restez informés de nos derniers projets"
                  >
                    Restez informés de nos derniers projets
                  </h2>
                </div>
              </div>
              <div className="column is-full-mobile is-half">
                <div className="content">
                  <div className="card has-background-light">
                    <div className="card-content">
                      <h3 className="title is-5">
                        Inscrivez-vous à la newsletter
                      </h3>
                      <form onSubmit={this.onSubmit}>
                        <div className="field">
                          <div className="control has-icons-left has-icons-right">
                            <input
                              className="input is-light"
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Saisissez votre e-mail..."
                              required
                              onChange={this.onChange}
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-envelope"></i>
                            </span>
                          </div>
                        </div>
                        <div className="control buttons">
                          <button type="submit" className="button is-primary">
                            Je m'inscris
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default NewSubscription;