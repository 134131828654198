import React, { Suspense, lazy } from "react";
import { Link } from "react-router-dom";

import Loader from "../Loader";

const NoData = lazy(() => import("../Loader/NoData"));
const NewSubscription = lazy(() => import("../Subscription/New"));

export default class Sales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemSales: []
    };
  }

  componentDidMount() {
    const url = "/api/v1/cars/index";

    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => this.setState({ itemSales: response }))
      .catch(() => this.props.history.push("/"));
  }

  render() {
    const { itemSales } = this.state;
    const allItemsList = itemSales.map((item, index) => (
      <div key={index} className="column is-full">
        <Link to={`/item/${item.id}`} className="">
          <div className="card">
            <div className="card-content">
              <div className="media">
                <div class="media-left">
                  <figure class="image is-128x128">
                    <img src={item.image.url} alt="Car image" />
                  </figure>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    {item.brand}
                  </p>
                  <p className="subtitle is-5">
                    {new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }).format(item.price)}
                  </p>
                  <p className="is-6">Ajouté le {item.created}</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    ));
    const allItems = (
      <section className="section has-background-light">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-full-mobile is-three-fifth">
              <div className="columns is-multiline">{allItemsList}</div>
            </div>

            <div className="column is-full-mobile is-two-fifths has-text-right">
              <img alt="JPX Motors" src="/jpxmotors-car-bro.svg" />
              <a
                className="is-size-7 is-italic is-family-sans-serif has-text-weight-light has-text-grey-lighter freepik-storyset"
                href="https://storyset.com/work"
                rel="noreferrer"
                target="_blank"
              >
                Illustration by Freepik Storyset
              </a>
            </div>
          </div>
        </div>
      </section>
    );
    const noItem = <NoData />;

    return (
      <React.Fragment>
        <section className="hero is-medium is-primary banner">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1
                className="title is-1 is-size-2-mobile is-white my-6 is-logo"
                title="Nos voitures"
              >
                Nos voitures
              </h1>
            </div>
          </div>
        </section>

        {itemSales.length > 0 ? allItems : noItem}

        <Suspense fallback={<Loader sectionName="small" />}>
          <NewSubscription />
        </Suspense>
      </React.Fragment>
    );
  }
}