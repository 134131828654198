import React from "react";

export default () => (
  <React.Fragment>
    <section className="section">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-two-fifths has-text-left">
            <figure className="image is-3by5">
              <img alt="JPX Motors" src="/64-interieur-porshe.jpg" />
            </figure>
          </div>

          <div className="column is-full-mobile is-three-fifths">
            <div className="content is-medium">
              <h2 className="title is-3 mt-6 mb-3 title-logo">JPXMotors</h2>
              <p className="has-text-justified">
                Société spécialisée dans l’entretien, le diagnostique, la
                réparation & la restauration de <strong>MASERATI</strong>, de toutes générations (60’ à nos
                jours).
              </p>
              <p className="has-text-justified">
                Elle est basée dans le Vexin dans l’atypique ville de Marines à
                35 minutes de L’ouest Parisien.
              </p>
              <p className="has-text-justified">
                Elle s’appuie sur l’expertise d’un passionné ayant passé plus
                d’une vingtaine d’années dans l’industrie Aéronautique. Formé
                sur des technologies de pointes et des mécaniques complexes
                (Rafale et Mirage 2000...).
              </p>
              <p className="has-text-justified">
                Il a depuis longtemps transposé ces techniques au service de sa
                passion automobile.
              </p>
              <p className="has-text-justified">
                Tombé sous le charme des <strong>Mécaniques Italiennes</strong>,
                Alfa Roméo, Lancia & Ferrari, il a depuis 15 ans jeté son
                dévolue sur la firme au Trident.
              </p>
              <p className="has-text-justified">
                La Genèse des <strong>Biturbo</strong> n’ayant plus de secret,
                l’histoire de cette firme le poussa à en découvrir l’intégralité
                de la gamme de la création à nos jours.
              </p>
              <p className="has-text-justified">
                Il propose désormais ses services dans une singulière et
                discrète officine.
              </p>

              <h3 className="subtitle is-4 my-4 is-primary">
                Les Anciennes et modernes s’y croisent avec une seule volonté
                pour <strong className="is-logo">JPXMotors</strong>, vous garantir un plaisir
                d’utilisation de tous les instants...
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section has-background-light">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-three-fifths">
            <div className="content is-medium">
              <h2 className="title is-3 mt-6 mb-3" title="Entretiens">
                Entretiens
              </h2>

              <p className="has-text-justified">
                <ul>
                  <li>Remplacement des fluides & Consommables</li>
                  <li>Distributions</li>
                  <li>Embrayage et transmission</li>
                  <li>Boite F1 et paramétrage de Grille</li>
                  <li>Suspension et Freinage</li>
                  <li>Électricité courante</li>
                </ul>
              </p>

              <h2
                className="title is-3 mt-6 mb-3"
                title="Réparations & Restaurations lourdes "
              >
                Réparations & Restaurations lourdes
              </h2>

              <p className="has-text-justified">
                <ul>
                  <li>Réfection intégrale des moteurs</li>
                  <li>Réfection complète des transmissions</li>
                  <li>Restauration totales</li>
                  <li>
                    Études, conceptions & fiabilisations d’éléments techniques
                    déficients ou mal conçues initialement
                  </li>
                  <li>Réfection complète des faisceaux électriques</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="column is-full-mobile is-two-fifths has-text-right">
            <figure className="image is-3by5">
              <img alt="JPX Motors" src="/64-arriere-porshe.jpg" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);