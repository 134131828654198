import React from "react";
import {animated, useSpring} from "react-spring";

export default function Gestionnaire() {
  const propsFadeIn = useSpring({
    config: {duration: 500},
    opacity: 1,
    from: {opacity: 0}
  });

  return (
    <React.Fragment>
      <animated.div style={propsFadeIn}>
        <div className="content is-medium">
          <div className="columns is-multiline">
            <div className="column is-full">
              <h3 className="subtitle has-text-centered mb-6">
                Syndicats de copropriétés | Gestionnaires d'actifs immobilier
              </h3>
            </div>

            <div className="column is-full-mobile is-half">
              <p className="has-text-justified">
                Vous souhaitez
                <strong>&nbsp;moderniser&nbsp;</strong> et devenir un syndicat
                100% digitalisé?
              </p>
              <p className="has-text-justified">
                Notre plateforme vous aidera dans votre transformation
                numérique.​
              </p>
              <p className="has-text-justified">
                Appréciez tous les outils à votre disposition afin de gérer
                votre syndicat et la mise en relation avec vos copropriétaires.
              </p>
            </div>

            <div className="column is-full-mobile is-half ">
              <div className="">
                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-32x32">
                        <i className="fa fa-city icon is-medium fas fa-2x is-pumpkin"></i>
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-4">Gains pour le syndicat</p>
                      <p className="subtitle is-6">
                        <em>Optimisez l'organisation de vos AG.</em>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-32x32">
                        <i className="fa fa-street-view icon is-medium fas fa-2x is-pumpkin"></i>
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-4">
                        Gains pour les copropriétaires
                      </p>
                      <p className="subtitle is-6">
                        Consultation et signature des documents à distance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </React.Fragment>
  );
}
