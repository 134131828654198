const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

export default {
  gender: value =>
    !value || value.trim() === "" ? "Votre civilité est requis." : undefined,

  first_name: value =>
    !value || value.trim() === "" ? "Votre prénom est requis." : undefined,

  last_name: value =>
    !value || value.trim() === "" ? "Votre nom est requis." : undefined,

  entreprise: value =>
    !value || value.trim() === ""
      ? "La forme juridique de votre société est requise."
      : undefined,

  email: value =>
    !value || !emailRegex.test(value)
      ? "L'e-mail n'est au bon format."
      : undefined,

  name: value =>
    !value || value.trim() === ""
      ? "Le nom de votre société est requis."
      : undefined,

  phone: value => {
    if (!value || value.trim() === "") {
      return "Le numéro de téléphone est requis.";
    }

    const stripped = value.replace(/[\(\)\.\-\ ]/g, "");

    if (isNaN(parseInt(stripped)) || stripped.length !== 10) {
      return "Le numéro de téléphone n'est au bon format.";
    }

    return undefined;
  },

  message: value =>
    !value || value.trim() === "" ? "Un message est requis." : undefined
};
