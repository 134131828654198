import React, { Suspense, lazy } from "react";

import Loader from "../Loader";

const Contact = lazy(() => import("../Contact/Banner"));
const Story = lazy(() => import("./Story"));

export default () => (
  <React.Fragment>
    <section className="hero is-medium is-primary banner">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1
            className="title is-1 is-size-2-mobile is-white my-6 is-logo"
            title="Qui suis-je ?"
          >
            Qui suis-je ?
          </h1>
        </div>
      </div>
    </section>

    <Suspense fallback={<Loader sectionName="small" />}>
      <Story />
      <Contact />
    </Suspense>
  </React.Fragment>
);