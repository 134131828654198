import React from "react";
const diagnostiquesImage = require("../../../assets/images/pictures/IMG_6450.JPG");

export default () => (
  <React.Fragment>
    <section className="section has-background-light">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-three-fifths">
            <div className="content is-medium">
              <h2 className="title is-3 mt-6 mb-3">
                Diagnostiques & Recherches
              </h2>
              <p className="has-text-justified">
                Des <strong>technologies de pointes</strong>, même sur des
                voitures anciennes, nous aideront à diagnostiquer des pannes ou
                des phénomènes complexes. Permettant souvent d’
                <strong>anticiper</strong> certaines pannes ou dérives
                prématurées.
              </p>
              <p className="has-text-justified">
                <ul>
                  <li>
                    Diagnostique et analyse de panne classique via OBD X431 V5
                  </li>
                  <li>
                    Recherche de panne aléatoire et diagnostique approfondie
                  </li>
                  <li>Remise en marche de véhicule en panne</li>
                  <li>Mesures techniques des différentes chaines de mesures</li>
                  <li>
                    Réparation des différents boitiers de (gestion moteur,
                    gestion de servitudes...).
                  </li>
                </ul>
              </p>

              <h2
                className="title is-3 mt-6 mb-3"
                title="Modélisation & fabrication de pièces indisponible "
              >
                Modélisation & fabrication de pièces indisponible
              </h2>
              <p className="has-text-justified">
                L’indisponibilité de bon nombres de pièces rendent la
                maintenance de nos autos compliquée voire impossible.
              </p>

              <p className="has-text-justified">
                <strong>JPX</strong> Motors fort de son expérience industrielle
                utilise des technologies de pointes (Scanner 3D, CAO...).
              </p>

              <p className="has-text-justified">
                Et adaptons la technologie au besoin, afin de maitriser les
                couts liés à la fabrication unitaire de pièces complexes.
              </p>

              <h3 className="subtitle is-4 my-4 is-primary">
                Des partenariats avec des sociétés hexagonales nous permettent
                une maitrise totales du process de fabrication et des délais
                associés.
              </h3>
            </div>
          </div>
          <div className="column is-full-mobile is-two-fifths has-text-right">
            <figure className="image is-3by5">
              <img
                alt="JPXMotors"
                src={diagnostiquesImage}
                width="100%"
                height="100%"
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);