import React from "react";
import {Link} from "react-router-dom";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toTop: null
    };
  }

  componentDidMount() {
    this.setState(
      {
        toTop: document.querySelector("#toTop")
      },
      () => {
        this.watchScroll(document.body);
      }
    );
  }

  watchScroll = element => {
    if (element.scrollTop > 10) {
      this.state.toTop.classList.add("backtop-top-show");
    } else {
      this.state.toTop.classList.remove("backtop-top-show");
    }

    document.body.onscroll = event => {
      this.watchScroll(event.target.documentElement);
    };
  };

  scrollToTop = () => {
    var scrollStep = -window.scrollY / (800 / 15);

    var scrollInterval = setInterval(() => {
      if (window.scrollY != 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };

  render() {
    return (
      <footer className="footer">
        <a id="toTop" className="go-top" onClick={this.scrollToTop}>
          <i className="fa fa-1-25 fa-angle-up" />
        </a>

        <div className="content">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h6>
                  <Link to={`/`} title="Copyright 2021" className="title-logo">
                    JPXMotors
                  </Link>
                </h6>
                <p>
                  Marines
                  <br />
                  Val d'Oise
                  <br />A 35 minutes de L’ouest Parisien
                </p>
              </div>
              <div className="column">
                <h6>Liens</h6>
                <ul className="alt">
                  <li>
                    <Link to={`/nos-services`} className="">
                      Nos services
                    </Link>
                  </li>
                  <li>
                    <Link to={`/decouvrir-nos-projets`} className="">
                      Decouvrir nos projets
                    </Link>
                  </li>
                  <li>
                    <Link to={`/a-propos`} className="">
                      Qui suis-je ?
                    </Link>
                  </li>
                  <li>
                    <Link to={`/a-vendre`} className="">
                      A vendre
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="column">
                <h6>Suivez-moi</h6>
                <ul className="alt">
                  <li>
                    <a
                      className=""
                      href="https://www.instagram.com/jpxmotors"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="icon fa fa-external-link-alt"></i>Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content has-text-centered">
          <p>
            © 2021{" "}
            <Link to={`/`} title="Copyright 2021" className="">
              JPXMotors
            </Link>{" "}
            - Tous droits reservés.
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
