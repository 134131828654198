import React from "react";

import Loader from "../Loader";

class Cgv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: []
    };
    this.addHtmlEntities = this.addHtmlEntities.bind(this);
  }

  componentDidMount() {
    const url = "/api/v1/pages/cgv";
    const token = document.querySelector('meta[name="csrf-token"]').content;

    fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => this.setState({pages: response}))
      .catch(() => this.props.history.push("/"));
  }

  addHtmlEntities(str) {
    return String(str)
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }
  render() {
    const {pages} = this.state;
    const allPages = pages.map((page, index) => (
      <div key={index} className="column is-full">
        <div className="content">
          <h2 className="title is-3">{page.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: `${this.addHtmlEntities(page.content)}`
            }}
          />
        </div>
      </div>
    ));
    const noPage = (
      <div className="column is-full">
        <Loader sectionName="small" />
      </div>
    );

    return (
      <>
        <div className="">
          <section className="hero is-medium is-primary banner">
            <div className="hero-body">
              <div className="container">
                <h1
                  className="title is-1 is-white"
                  title="Conditions générales de ventes"
                >
                  Conditions générales de ventes
                </h1>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="container">
              <div className="columns is-multiline">
                {pages.length > 0 ? allPages : noPage}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
export default Cgv;
