import React from "react";
import {animated, useSpring} from "react-spring";

export default function Collectivite() {
  const propsFadeIn = useSpring({
    config: {duration: 500},
    opacity: 1,
    from: {opacity: 0}
  });

  return (
    <React.Fragment>
      <animated.div style={propsFadeIn}>
        <div className="content is-medium">
          <div className="columns is-multiline">
            <div className="column is-full">
              <h3
                className="subtitle has-text-centered mb-6"
                title="Mairie | Départementales | Régionales"
              >
                Mairie | Départementales | Régionales
              </h3>
            </div>

            <div className="column is-full-mobile is-half">
              <p className="has-text-justified">
                <strong>
                  <span className="is-logo">JPXMotors</span>
                </strong>
                &nbsp;vous souhaite la bienvenue!​
              </p>

              <p className="has-text-justified">
                Vous souhaitez <strong>moderniser</strong> dans l'oganisation de
                vos évènements publiques?
              </p>
              <p className="has-text-justified">
                Notre plateforme vous aidera dans votre transformation numérique
                et faciliter vos échanges entre élus et citoyens.
              </p>
            </div>

            <div className="column is-full-mobile is-half ">
              <div className="">
                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-32x32">
                        <i className="fa fa-city icon is-medium fas fa-2x is-pomegranate"></i>
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-4">Gain pour la Collectivité</p>
                      <p className="subtitle is-6">
                        Facilitez les prises de décisions de vos Assemblées
                        Générales.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card-content">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-32x32">
                        <i className="fa fa-street-view icon is-medium fas fa-2x is-pomegranate"></i>
                      </figure>
                    </div>
                    <div className="media-content">
                      <p className="title is-4">Gain pour les citoyens</p>
                      <p className="subtitle is-6">
                        Offrez un accès à distance à vos participants.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </React.Fragment>
  );
}
