import React from "react";

export default function Loader(props) {
  return (
    <section className={`section section-loader ${props.sectionName}`}>
      <svg width="100" height="100" className="loader-double">
        <circle cx="50" cy="50" r="45" className="loader-circle-double" />
      </svg>
    </section>
  );
}
