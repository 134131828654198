import React, {useRef, useState, useEffect, useCallback} from "react";
import {useTransition, animated, useSpring} from "react-spring";
import {Link} from "react-router-dom";
import "./header.css";

export default function Header() {
  const propsFadeIn = useSpring({
    config: {duration: 500},
    opacity: 1,
    from: {opacity: 0}
  });
  const ref = useRef([]);
  const [items, set] = useState([]);
  const transitionsHeader = useTransition(items, null, {
    from: {
      opacity: 0,
      height: 0,
      innerHeight: 0,
      transform: "perspective(300px) rotateX(0deg)",
      color: "#bdc3c7"
    },
    enter: [
      {opacity: 1, height: 60, innerHeight: 60},
      {transform: "perspective(300px) rotateX(0deg)", color: "#d7591a"},
      {color: "#bdc3c7"}
    ],
    leave: [{color: "#95160c"}, {innerHeight: 0}, {opacity: 0, height: 0}],
    update: {color: "#910019"}
  });

  const reset = useCallback(() => {
    ref.current.map(clearTimeout);
    ref.current = [];
    set([]);
    ref.current.push(
      setTimeout(() => set(["Entretien", "Diagnostique", "Réparation"]), 2000)
    );
    ref.current.push(
      setTimeout(
        () => set(["Restauration lourde", "Recherche", "Modélisation"]),
        5000
      )
    );
    ref.current.push(
      setTimeout(
        () =>
          set([
            "Entretien & Réparation",
            "Restauration lourde",
            "Diagnostique & Recherche",
            "Modélisation & Fabrication"
          ]),
        8000
      )
    );
  }, []);

  useEffect(() => void reset(), []);

  return (
    <section className="section has-background-light is-medium">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-full-tablet is-three-fifths">
            <div className="content is-small">
              <animated.div style={propsFadeIn}>
                <h2
                  className="title is-1 is-size-2-mobile my-6 is-ligth is-logo"
                  title="JPXMotors"
                >
                  JPXMotors
                </h2>
              </animated.div>

              {transitionsHeader.map(
                ({ item, props: { innerHeight, ...rest }, key }) => (
                  <animated.div
                    className="transitions-item"
                    key={key}
                    style={rest}
                    onClick={reset}
                  >
                    <animated.div
                      style={{ overflow: "hidden", height: innerHeight }}
                    >
                      {item}
                    </animated.div>
                  </animated.div>
                )
              )}
            </div>
          </div>

          <div className="column is-full-mobile is-hidden-touch">
            <figure className="image is-3by5">
              <img alt="JPX Motors" src="/64-arriere-porshe.jpg" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}
