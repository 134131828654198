import React, {Suspense, lazy, useState, useEffect} from "react";

const SITE_KEY = "XXXXX666666LeEfTgaAAAAAD6PeR_myWq6e0h0iC1fiodWExFV";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import Loader from "../Loader";
import {Form, RadioGroup, Radio, Text, Input, Select, TextArea} from "informed";

const NewSubscription = lazy(() => import("../Subscription/New"));

import {observer, inject} from "mobx-react";

@inject("routing")
@observer
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      gender: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      message: null,
      loading: false,
      response: null
    };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.submitData = this.submitData.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  onChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  submit = () => {
    this.setState({loading: true});
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, {action: "submit"}).then(token => {
        this.submitData(token);
      });
    });
  };

  submitData = token => {
    const {push} = this.props.routing;
    toastr.options = {
      positionClass: "toast-top-full-width",
      hideDuration: 300,
      timeOut: 3000
    };

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    fetch("/api/v1/contacts/create", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        gender: this.state.gender,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
        "g-recaptcha-response": token
      })
    })
      .then(res => {
        if (res.ok) {
          setTimeout(
            () =>
              toastr.success(
                `Votre demande de contact a été envoyée avec succès.`
              ),
            300
          );
        } else {
          setTimeout(
            () =>
              toastr.error(
                "Oups! Votre demande de contact n'a pas été envoyée."
              ),
            300
          );
        }

        return res.json();
      })
      .then(res => {
        this.setState({loading: false});
        this.setState({response: res});

        this.setState({token: ""});
        this.setState({gender: ""});
        this.setState({firstName: ""});
        this.setState({lastName: ""});
        this.setState({email: ""});
        this.setState({phone: ""});
        this.setState({message: ""});
      })
      .then(res => {
        push(`/`);
      });
  };

  validateField = value => {
    if (!value || value.trim() === "") {
      toastr.clear();
      setTimeout(
        () =>
          toastr.error("Oups! Vous devez compléter le champ avec votre email."),
        300
      );
      return "Vous devez compléter le champ avec votre email.";
    }
  };

  render() {
    const {gender} = this.state;
    const {firstName} = this.state;
    const {lastName} = this.state;
    const {email} = this.state;
    const {phone} = this.state;
    const {message} = this.state;
    const {loading} = this.state;
    const {response} = this.state;

    return (
      <React.Fragment>
        <section className="hero is-medium is-primary banner">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1
                className="title is-1 is-size-2-mobile is-white my-6 is-logo"
                title="Contactez-nous"
              >
                Contactez-nous
              </h1>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <h2
              className="title is-3 has-text-centered is-primary is-logo"
              title="JPXMotors"
            >
              JPXMotors
            </h2>
            <h3
              class="subtitle has-text-centered is-light is-4"
              title="Partagez avec nous vos projets pour trouver ensemble le meilleur moyen de les réaliser."
            >
              Partagez avec nous vos projets pour trouver ensemble le meilleur
              moyen de les réaliser.
            </h3>

            <div className="columns is-multiline">
              <div className="column is-half is-full-mobile">
                <Form onSubmit={this.submit}>
                  <div className="field">
                    <div className="control field-label">
                      <label className="label">Civilité</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <RadioGroup field="gender" initialValue={gender}>
                            <label htmlFor="gender-mr">
                              Mr{" "}
                              <Radio
                                value="mr"
                                id="gender-mr"
                                className="radio"
                                required
                                onChange={this.onChange}
                              />
                            </label>{" "}
                            <label htmlFor="gender-mme">
                              Mme{" "}
                              <Radio
                                value="mme"
                                id="gender-mme"
                                className="radio"
                                required
                                onChange={this.onChange}
                              />
                            </label>
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor="firstName">
                      Prénom
                    </label>
                    <div className="control">
                      <Text
                        field="firstName"
                        initialValue={firstName}
                        className="input"
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor="lastName">
                      Nom
                    </label>
                    <div className="control">
                      <Text
                        field="lastName"
                        initialValue={lastName}
                        className="input"
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor="phone">
                      Tel.
                    </label>
                    <div className="control">
                      <Input
                        id="phone"
                        field="phone"
                        initialValue={phone}
                        type="number"
                        className="input"
                        required
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor="email">
                      Email
                    </label>
                    <div className="control">
                      <Input
                        type="email"
                        field="email"
                        initialValue={email}
                        className="input"
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor="message">
                      Votre message
                    </label>
                    <div className="control">
                      <TextArea
                        field="message"
                        className="textarea"
                        initialValue={message}
                        rows="5"
                        placeholder="Saisissez votre message ici"
                        required
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="control buttons">
                    <button
                      type="submit"
                      className="button is-primary"
                      disabled={loading}
                    >
                      {loading
                        ? "Demande en cours de traitement..."
                        : "Envoyer ma demande"}
                    </button>
                  </div>
                </Form>
              </div>
              <div className="column is-half is-full-mobile">
                
              </div>
            </div>
          </div>
        </section>

        <Suspense fallback={<Loader sectionName="small" />}>
          <NewSubscription />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Contact;
