import React from "react";

export default () => (
  <React.Fragment>
    <section className="section">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-two-fifths has-text-left">
            <figure className="image is-3by5">
              <img
                alt="JPXMotors"
                src="/carpixel.net-1970-maserati-ghibli-ss-59950-wide.jpg"
              />
            </figure>
          </div>

          <div className="column is-full-mobile is-three-fifths">
            <div className="content is-medium">
              <h2 className="title is-3 mt-6 mb-3 is-logo" title="JPX Motors">
                JPXMotors
              </h2>
              <p className="has-text-justified">
                Société spécialisée dans l’entretien, le diagnostique, la
                réparation & la restauration de <strong>MASERATI</strong>, de
                toutes générations (60’ à nos jours).
              </p>
              <p className="has-text-justified">
                Elle est basée dans le Vexin dans l’atypique ville de Marines à
                35 minutes de L’ouest Parisien.
              </p>
              <p className="has-text-justified">
                Elle s’appuie sur l’expertise d’un passionné ayant passé plus
                d’une vingtaine d’années dans l’industrie Aéronautique. Formé
                sur des technologies de pointes et des mécaniques complexes
                (Rafale et Mirage 2000...).
              </p>
              <p className="has-text-justified">
                Il a depuis longtemps transposé ces techniques au service de sa
                passion automobile.
              </p>
              <p className="has-text-justified">
                Tombé sous le charme des <strong>Mécaniques Italiennes</strong>,
                il a depuis 15 ans jeté son dévolue sur la firme au Trident.
              </p>
              <p className="has-text-justified">
                La Genèse des <strong>Biturbo</strong> n’ayant plus de secret,
                l’histoire de cette firme le poussa à en découvrir l’intégralité
                de la gamme de la création à nos jours.
              </p>
              <p className="has-text-justified">
                Il propose désormais ses services dans une singulière et
                discrète officine.
              </p>
              <h3 className="subtitle is-4 my-4">
                Les Anciennes et modernes s’y croisent avec une seule volonté
                pour <strong>JPXMotors</strong>, vous garantir un plaisir
                d’utilisation de tous les instants...
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);