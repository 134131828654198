import React from "react";

export default () => (
  <React.Fragment>
    <section className="section">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/192-maserati-back-brand.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        alt="JPX Motors"
                        src="/android-chrome-192x192.png"
                        width="100%"
                        height="100%"
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4 is-logo">Marque du véhicule</p>
                    <p className="subtitle is-6">Modele</p>
                  </div>
                </div>

                <div className="content">
                  <time datetime="01-01-2021">Année</time>
                  <br />
                  <a href="#">#tag</a> <a href="#">#tag</a>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/64-maserati-galery-pic-23.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4 is-logo">Maserati</p>
                    <p className="subtitle is-6">Coupé</p>
                    <p className="subtitle is-6">
                      <time datetime="01-03-2021">2002</time>
                    </p>
                  </div>
                </div>

                <div className="content">
                  Maserati est un constructeur automobile italien spécialisé
                  dans les voitures de luxe, de sport et de course, filiale du
                  groupe Stellantis. Fondé par les frères Maserati en 1914, son
                  symbole est un trident, inspiré de la fontaine de Neptune de
                  Bologne.
                  <a href="#">#italienne</a> <a href="#">#puissance</a>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/192-maserati-banner.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        alt="JPX Motors"
                        src="/android-chrome-192x192.png"
                        width="100%"
                        height="100%"
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4 is-logo">Maserati</p>
                    <p className="subtitle is-6">Zagato</p>
                  </div>
                </div>

                <div className="content">
                  <a href="#">#maserati</a> <a href="#">#italienne</a>{" "}
                  <a href="#">#puissance</a>
                  <br />
                  <time datetime="07-07-2021">1946-1960</time>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/128-ferrari-galery-pic1.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        alt="JPX Motors"
                        src="/android-chrome-192x192.png"
                        width="100%"
                        height="100%"
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4 is-logo">Ferrari</p>
                    <p className="subtitle is-6">Jantes</p>
                  </div>
                </div>

                <div className="content">
                  <time datetime="26-08-2021">2019</time>
                  <br />
                  <a href="#">#ferrari</a>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="JPX Motors" src="/64-maserati-brand-front.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        alt="JPX Motors"
                        src="/android-chrome-192x192.png"
                        width="100%"
                        height="100%"
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4 is-logo">Maserati</p>
                    <p className="subtitle is-6">GranCabrio</p>
                  </div>
                </div>

                <div className="content">
                  La GranCabrio est la version cabriolet de la GranTurismo S
                  Automatic. Elle est dévoilée au salon de l'automobile de
                  Francfort 2009, fabriquée dans l'usine Maserati de Viale Ciro
                  Menotti, et commercialisée à partir de 2010. Trois versions
                  verront le jour.
                  <a href="#">#italienne</a> <a href="#">#puissance</a>
                  <br />
                  <time datetime="01-01-2021">2010</time>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-3by5">
                  <img alt="JPX Motors" src="/64-interieur-porshe.jpg" />
                </figure>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-3by5">
                  <img alt="JPX Motors" src="/192-porshe-galery-pic11.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4 is-logo">Porshe</p>
                    <p className="subtitle is-6">Modèle</p>
                    <p className="subtitle is-6">
                      <time datetime="01-03-2021">Année</time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-3by5">
                  <img alt="JPX Motors" src="/192-porshe-galery-pic1.jpg" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);