import {RouterStore} from "mobx-react-router";

const routingStore = new RouterStore();

const store = {
  routing: routingStore
};

console.log({
  ...store
});

export default store;
