import React, {Suspense, lazy} from "react";
import {Link} from "react-router-dom";

import Loader from "../Loader";

const NoData = lazy(() => import("../Loader/NoData"));
const NewSubscription = lazy(() => import("./../Subscription/New"));

class Posts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
  }

  componentDidMount() {
    const url = "/api/v1/posts/index";
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => this.setState({posts: response}))
      .catch(() => this.props.history.push("/"));
  }

  Greeting(props) {
    const isLoggedIn = props.isLoggedIn;
    if (isLoggedIn) {
      return <UserGreeting />;
    }
    return <GuestGreeting />;
  }

  render() {
    const {posts} = this.state;
    const allPostsList = posts.map((post, index) => (
      <div key={index} className="column is-full-mobile is-half">
        <div className="card">
          <div className="card-content">
            <div className="content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-5">{post.title}</p>
                  <p className="subtitle is-6">{post.created}</p>
                </div>
              </div>

              <div className="content buttons has-text-centered">
                <Link to={`/post/${post.id}`} className="button is-primary">
                  Lire
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    const allPosts = (
      <section className="section is-large has-background-light">
        <div className="container">
          <div className="columns is-multiline">{allPostsList}</div>
        </div>
      </section>
    );
    const noPost = <NoData />;

    return (
      <React.Fragment>
        <section className="hero is-medium is-primary banner">
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title" title="Dernières actualités">
                Dernières actualités
              </h1>
            </div>
          </div>
        </section>
        <div className="">{posts.length > 0 ? allPosts : noPost}</div>

        <Suspense fallback={<Loader sectionName="small" />}>
          <NewSubscription />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default Posts;
