import React from "react";

export default () => (
  <React.Fragment>
    <section className="section has-background-light">
      <div className="container">
        <h2
          className="title is-3 is-size-4-mobile is-primary my-6 title-logo has-text-centered"
          title="Présentation"
        >
          Présentation
        </h2>
        <div className="columns is-multiline">
          <div className="column is-full-mobile is-half has-text-left">
            <div className="card">
              <div className="card-image">
                <figure className="image is-5by4">
                  <img alt="Mr. Jeremy.P" src="/64-maserati-brand-front.jpg" />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img alt="Mr. Jeremy.P" src="/man.jpg" />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Mr. Jeremy.P</p>
                    <p className="subtitle is-6">
                      <a href="#">#moteur</a>
                    </p>
                  </div>
                </div>

                <div className="content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
);