import React, {useState, useEffect, useCallback} from "react";
import {Link} from "react-router-dom";
import {useTransition, animated} from "react-spring";
import "./Banner.css";

import NoData from "../Loader/NoData";

import useArticles from "../useArticles";

const pages = [
  ({style}) => (
    <animated.div style={{...style, background: "darkorange"}}>
      Un gestionnaire immobilier
    </animated.div>
  ),
  ({style}) => (
    <animated.div style={{...style, background: "tomato"}}>
      Une entreprise
    </animated.div>
  ),
  ({style}) => (
    <animated.div style={{...style, background: "midnightblue"}}>
      Un tiers de confiance
    </animated.div>
  ),
  ({style}) => (
    <animated.div style={{...style, background: "orangered"}}>
      Une collectivité
    </animated.div>
  )
];

export default function Banner() {
  const [articles, setArticles] = useState([]);
  const [index, setIndex] = useState(0);

  const updateArticle = articles => {
    setArticles(articles);
  };

  useEffect(() => {
    const url = "/api/v1/articles/index";
    fetch(url)
      .then(response => {
        if (response.ok) {
          console.log(response);

          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(response => updateArticle(response))
      .catch(() => console.log("ici"));

    return () => {
      cleanup;
    };
  }, []);

  const articlesBanner = useArticles();

  const onClick = useCallback(() => setIndex(state => (state + 1) % 4), []);
  const transitionsSlide = useTransition(index, p => p, {
    from: {opacity: 0, transform: "translate3d(100%,0,0)"},
    enter: {opacity: 1, transform: "translate3d(0%,0,0)"},
    leave: {opacity: 0, transform: "translate3d(-50%,0,0)"}
  });

  const allArticlesList = articles.map((article, index) => (
    <div key={index} className="column is-full">
      <div className="card">
        <div className="card-content">
          <div className="content">
            <div className="media">
              <div className="media-content">
                <p className="title is-5">{article.title}</p>
                <p className="subtitle is-6">{article.created}</p>
              </div>
            </div>

            <div className="content buttons has-text-centered">
              <Link to={`/projet/${article.id}`} className="button is-primary">
                Lire
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
  const allArticles = (
    <section className="section has-background-light">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-full has-text-centered">
            <h2 className="title is-2 mb-6">Restez informés</h2>
          </div>

          <div className="column is-full-mobile is-three-fifth">
            <div className="columns is-multiline">{allArticlesList}</div>
          </div>

          <div className="column is-full-mobile is-two-fifths has-text-right">
            <img alt="JPX Motors" src="/jpxmotors-news.svg" />
            <a
              className="is-size-7 is-italic is-family-sans-serif has-text-weight-light has-text-grey-lighter freepik-storyset"
              href="https://storyset.com/work"
              rel="noreferrer"
              target="_blank"
            >
              Illustration by Freepik Storyset
            </a>
          </div>
        </div>
      </div>
    </section>
  );
  const noArticle = <NoData />;

  return (
    <section className="section">
      <div>{articles.length > 0 ? allArticles : noArticle}</div>
      <div className="container">
        <div className="simple-trans-main" onClick={onClick}>
          {transitionsSlide.map(({item, props, key}) => {
            const Page = pages[item];
            return <Page key={key} style={props} />;
          })}
        </div>
      </div>
    </section>
  );
}
