import React, {useState, useEffect} from "react";

export default function useArticles() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const url = "/api/v1/articles/index";
    const fetchArticles = async () => {
      const response = await fetch(url);
      const data = await response;
      console.log(data);
      setArticles(data);
    };

    fetchArticles();
  }, []);

  return articles;
}
